<template>
  <v-container
    ><v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-row justify="end">
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense color="primary" group>
                <v-btn
                  value="CONVENIOS"
                  v-if="canSeeConvenios"
                  :disabled="mostrarTablaConvenios"
                  @click="changeTab(1)"
                  >Convenios</v-btn
                >
                <v-btn
                  value="PARENTESCOS"
                  v-if="canSeeParentescos"
                  :disabled="mostrarTablaParentescos"
                  @click="changeTab(2)"
                  >Parentescos</v-btn
                >
                <v-btn
                  value="COBERTURA ESPECIAL"
                  v-if="canSeeCoberturaEspecial"
                  :disabled="mostrarTablaCoberturaEspecial"
                  @click="changeTab(3)"
                  >Tipos de cobertura especial</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>

          <!-- Tabla Convenios -->
          <div v-if="mostrarTablaConvenios">
            <v-data-table
              class="elevation-1"
              :headers="bajasAutomaticasConveniosHeaders"
              :items="bajasAutomaticasConveniosItems"
              item-key="osId"
              :search="searchConvenios"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="10" align-self="center">
                      <v-text-field
                        v-model="searchConvenios"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20px"
                      v-on="on"
                      @click="openModalEditConvenios(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar convenios</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.osBajaAutoMay21`]="{ item }">
                <v-icon
                  v-if="item.osBajaAutoMay21"
                  small
                  class="primary--text"
                  >{{ checkIcon }}</v-icon
                >
              </template>
            </v-data-table>
          </div>

          <!-- Tabla Parentescos -->
          <div v-if="mostrarTablaParentescos">
            <v-data-table
              class="elevation-1"
              :headers="bajasAutomaticasParentescosHeaders"
              :items="bajasAutomaticasParentescosItems"
              item-key="parId"
              :search="searchParentescos"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="10" align-self="center">
                      <v-text-field
                        v-model="searchParentescos"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20px"
                      v-on="on"
                      @click="modalEditParentescos(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar parentescos</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.esBajaAutomatica`]="{ item }">
                <v-icon
                  v-if="item.esBajaAutomatica"
                  small
                  class="primary--text"
                  >{{ checkIcon }}</v-icon
                >
              </template>
              <template v-slot:[`item.sinCertificadoEstudio`]="{ item }">
                <v-icon
                  v-if="item.sinCertificadoEstudio"
                  small
                  class="primary--text"
                  >{{ checkIcon }}</v-icon
                >
              </template>
            </v-data-table>
          </div>

          <!-- Tabla Tipo de cobertura especial -->
          <div v-if="mostrarTablaCoberturaEspecial">
            <v-data-table
              class="elevation-1"
              :headers="bajasAutomaticasCoberturasEspecialesHeaders"
              :items="bajasAutomaticasCoberturasEspecialesItems"
              item-key="parId"
              :search="searchTipoCobEspecial"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="10" align-self="center">
                      <v-text-field
                        v-model="searchTipoCobEspecial"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20px"
                      v-on="on"
                      @click="openModalEditTipoCoberturaEspecial(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar tipo de cobertura especial</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.discapacidad`]="{ item }">
                <v-icon
                  v-if="item.discapacidad"
                  small
                  class="primary--text"
                  >{{ checkIcon }}</v-icon
                >
              </template>
              <template v-slot:[`item.esCertificadoEstudio`]="{ item }">
                <v-icon
                  v-if="item.esCertificadoEstudio"
                  small
                  class="primary--text"
                  >{{ checkIcon }}</v-icon
                >
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-8 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalEdits"
      v-model="modalEdits"
      :max-width="modalEditMaxWidth"
      @keydown.esc="closeModal"
      persistent
    >
      <EditConfigBajasAutomaticas
        :convenioObj="convenioObj"
        :parentescoObj="parentescoObj"
        :coberturaEspecialObj="coberturaEspecialObj"
        @closeAndReload="closeAndReload"
      ></EditConfigBajasAutomaticas>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditConfigBajasAutomaticas from "@/components/modules/afiliaciones/afiliados/EditConfigBajasAutomaticas.vue";

export default {
  name: "ConfigBajasAutomaticas",
  components: {
    PageHeader,
    GoBackBtn,
    EditConfigBajasAutomaticas,
    Ayuda
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    this.fetchBajasAutomaticas();
  },
  data() {
    return {
      title: enums.titles.CONFIG_BAJAS_AUTOMATICAS,
      optionCode: enums.webSiteOptions.CONFIG_BAJAS_AUTOMATICAS,
      showHelp: false,
      showExpand: false,
      allowedActions: null,
      showIcon: true,
      canSeeConvenios: false,
      canSeeParentescos: false,
      canSeeCoberturaEspecial: false,
      mostrarTablaConvenios: false,
      mostrarTablaParentescos: false,
      mostrarTablaCoberturaEspecial: false,
      modalEdits: false,
      convenioObj: null,
      parentescoObj: null,
      coberturaEspecialObj: null,
      routeToGo: "BajasAutomaticas",
      editIcon: enums.icons.EDIT,
      checkIcon: enums.icons.CHECK_OUTLINE,
      searchIcon: enums.icons.SEARCH,
      searchConvenios: null,
      searchParentescos: null,
      searchTipoCobEspecial: null,
      bajasAutomaticasConveniosItems: [],
      bajasAutomaticasParentescosItems: [],
      bajasAutomaticasCoberturasEspecialesItems: [],
      bajasAutomaticasConveniosHeaders: [
        {
          text: "Nombre",
          sortable: false,
          align: "start",
          value: "osNom"
        },
        {
          text: "Baja automática",
          align: "center",
          sortable: false,
          value: "osBajaAutoMay21"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ],
      bajasAutomaticasParentescosHeaders: [
        {
          text: "Parentesco",
          sortable: false,
          align: "start",
          value: "parNom"
        },
        {
          text: "Edad desde",
          align: "center",
          sortable: false,
          value: "edadDesde"
        },
        {
          text: "Edad hasta",
          sortable: false,
          align: "center",
          value: "edadHasta"
        },
        {
          text: "Es baja automática",
          sortable: false,
          align: "center",
          value: "esBajaAutomatica"
        },
        {
          text: "Motivo de baja",
          sortable: false,
          align: "start",
          value: "motivoBajaNom"
        },
        {
          text: "Sin certificado de estudio",
          sortable: false,
          align: "center",
          value: "sinCertificadoEstudio"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ],
      bajasAutomaticasCoberturasEspecialesHeaders: [
        {
          text: "Tipo cobertura nombre",
          sortable: false,
          align: "start",
          value: "tipoCobNom"
        },
        {
          text: "Discapacidad",
          align: "center",
          sortable: false,
          value: "discapacidad"
        },
        {
          text: "Es certificado estudio",
          align: "center",
          sortable: false,
          value: "esCertificadoEstudio"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ]
    };
  },
  computed: {
    // dependiendo de cual queremos editar será el tamaño del dialog modal edit para que se ajuste mejor
    modalEditMaxWidth() {
      if (this.convenioObj != null) return "30%";
      if (this.parentescoObj != null) return "35%";
      if (this.coberturaEspecialObj != null) return "30%";
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getConfigBajasAutomaticasConvenios:
        "afiliaciones/getConfigBajasAutomaticasConvenios",
      getConfigBajasAutomaticasParentescos:
        "afiliaciones/getConfigBajasAutomaticasParentescos",
      getConfigBajasAutomaticasCoberturasEspeciales:
        "afiliaciones/getConfigBajasAutomaticasCoberturasEspeciales"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .CONFIG_BAJAS_AUTOMATICAS_CONVENIOS:
            this.canSeeConvenios = true;
            // Mostrar la tabla al iniciar si esta habilitada la pestaña correspondiente
            if (this.canSeeConvenios) {
              this.mostrarTablaConvenios = true;
            }
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_BAJAS_AUTOMATICAS_PARENTESCOS:
            this.canSeeParentescos = true;
            // Mostrar la tabla al iniciar si esta habilitada la pestaña correspondiente
            if (this.canSeeParentescos && !this.canSeeConvenios) {
              this.mostrarTablaParentescos = true;
            }
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_BAJAS_AUTOMATICAS_COBERTURA_ESPECIAL:
            this.canSeeCoberturaEspecial = true;
            // Mostrar la tabla al iniciar si esta habilitada la pestaña correspondiente
            if (
              this.canSeeCoberturaEspecial &&
              !this.canSeeConvenios &&
              !this.canSeeParentescos
            ) {
              this.mostrarTablaCoberturaEspecial = true;
            }
            break;
          default:
            break;
        }
      });
    },
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarTablaConvenios = true;
          this.mostrarTablaParentescos = false;
          this.mostrarTablaCoberturaEspecial = false;
          break;
        case 2:
          this.mostrarTablaConvenios = false;
          this.mostrarTablaParentescos = true;
          this.mostrarTablaCoberturaEspecial = false;
          break;
        case 3:
          this.mostrarTablaConvenios = false;
          this.mostrarTablaParentescos = false;
          this.mostrarTablaCoberturaEspecial = true;
          break;
      }
    },
    async getConvenios() {
      const response = await this.getConfigBajasAutomaticasConvenios();
      this.bajasAutomaticasConveniosItems = response;
    },
    async getParentescos() {
      const response = await this.getConfigBajasAutomaticasParentescos();
      this.bajasAutomaticasParentescosItems = response;
    },
    async getCobEspeciales() {
      const response = await this.getConfigBajasAutomaticasCoberturasEspeciales();
      this.bajasAutomaticasCoberturasEspecialesItems = response;
    },
    fetchBajasAutomaticas() {
      this.getConvenios();
      this.getParentescos();
      this.getCobEspeciales();
    },
    openModalEditConvenios(item) {
      this.modalEdits = true;
      this.convenioObj = item;
      this.parentescoObj = null;
      this.coberturaEspecialObj = null;
    },
    modalEditParentescos(item) {
      this.modalEdits = true;
      this.convenioObj = null;
      this.parentescoObj = item;
      this.coberturaEspecialObj = null;
    },
    openModalEditTipoCoberturaEspecial(item) {
      this.modalEdits = true;
      this.convenioObj = null;
      this.parentescoObj = null;
      this.coberturaEspecialObj = item;
    },
    closeModal() {
      this.modalEdits = false;
    },
    closeAndReload() {
      this.modalEdits = false;
      this.fetchBajasAutomaticas();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.active {
  color: #f8f9fa !important;
  background-color: #195472 !important;
}
.scroll {
  overflow-y: scroll;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
