<template>
  <div>
    <!-- EDITAR CONVENIO -->
    <v-card v-if="convenioObj != null">
      <v-container>
        <v-card-title class="pl-4 primary--text">
          Editar convenio
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="isFormValid"
            ref="formConvenio"
            form="formConvenio"
            id="formConvenio"
            @submit.prevent="saveEditConvenio()"
          >
            <!-- Nombre -->
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  label="Nombre"
                  disabled
                  v-model="convenioNombre"
                ></v-text-field>
              </v-col>

              <!-- Baja automatica -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-switch
                  class="my-0"
                  v-model="convenioBaja"
                  label="Baja automática"
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModal"> Cancelar </v-btn>
              <v-btn
                type="submit"
                :disabled="!isFormValid"
                form="formConvenio"
                color="primary"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>

    <!-- EDITAR PARENTESCO -->
    <v-card v-if="parentescoObj != null">
      <v-container>
        <v-card-title class="pl-4 primary--text">
          Editar parentesco
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="isFormValid"
            ref="formParentesco"
            form="formParentesco"
            id="formParentesco"
            @submit.prevent="saveEditParentesco()"
          >
            <!-- Parentesco -->
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  label="Parentesco"
                  disabled
                  v-model="parentescoNombre"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Es baja automatica -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-switch
                  class="my-0"
                  v-model="parentescoBajaAutomatica"
                  label="Es baja automática"
                  item-text="value"
                  item-value="id"
                  dense
                  @change="resetInputsBajaAutomatica"
                  outlined
                ></v-switch>
              </v-col>
              <!-- Edad desde -->
              <v-col
                cols="6"
                md="6"
                class="pr-2 py-0"
                v-if="parentescoBajaAutomatica"
              >
                <v-text-field
                  v-model="parentescoEdadDesde"
                  label="Edad desde"
                  dense
                  onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                  outlined
                  :rules="
                    parentescoEdadDesde === 0
                      ? []
                      : rules.required.concat([
                          rules.maxNumber(parentescoEdadDesde, 125),
                          rules.validDateRange(
                            parseInt(parentescoEdadDesde),
                            parseInt(parentescoEdadHasta)
                          )
                        ])
                  "
                ></v-text-field>
              </v-col>
              <!-- Edad hasta -->
              <v-col
                cols="6"
                md="6"
                class="pl-2 py-0"
                v-if="parentescoBajaAutomatica"
              >
                <v-text-field
                  v-model="parentescoEdadHasta"
                  label="Edad hasta"
                  dense
                  onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                  outlined
                  :rules="
                    parentescoEdadHasta === 0
                      ? []
                      : rules.required.concat([
                          rules.maxNumber(parentescoEdadHasta, 125),
                          rules.validDateRange(
                            parseInt(parentescoEdadDesde),
                            parseInt(parentescoEdadHasta)
                          )
                        ])
                  "
                ></v-text-field>
              </v-col>
              <!-- Motivo de baja -->
              <v-col
                cols="12"
                md="12"
                class="pb-0 pt-2"
                v-if="parentescoBajaAutomatica"
              >
                <v-autocomplete
                  ref="parentesco-autocomplete"
                  :items="parentescoMotivoBaja"
                  :rules="rules.required"
                  v-model="parentescoMotivoBajaSelected"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                  autocomplete="off"
                  label="Motivo de baja"
                >
                </v-autocomplete>
              </v-col>
              <!-- Sin certificado de estudio -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-switch
                  class="my-0"
                  v-model="parentescoSinCertEstudio"
                  label="Sin certificado de estudio"
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModal"> Cancelar </v-btn>
              <v-btn
                type="submit"
                :disabled="!isFormValid"
                form="formParentesco"
                color="primary"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>

    <!-- EDITAR TIPO DE COBERTURA ESPECIAL -->
    <v-card v-if="coberturaEspecialObj != null">
      <v-container>
        <v-card-title class="pl-4 primary--text">
          Editar tipo de cobertura especial
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="isFormValid"
            ref="formTipoCobEsp"
            form="formTipoCobEsp"
            id="formTipoCobEsp"
            @submit.prevent="saveEditCobEspecial()"
          >
            <!-- Nombre -->
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  label="Nombre"
                  disabled
                  v-model="cobEspecialNombre"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Certificado estudio -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-switch
                  class="my-0"
                  v-model="cobEspecialEsCertEstudio"
                  label="Es certificado estudio"
                  item-text="value"
                  item-value="id"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModal"> Cancelar </v-btn>
              <v-btn
                type="submit"
                :disabled="!isFormValid"
                form="formTipoCobEsp"
                color="primary"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  props: {
    convenioObj: {
      type: Object,
      required: false
    },
    parentescoObj: {
      type: Object,
      required: false
    },
    coberturaEspecialObj: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      rules: rules,
      isFormValid: false,
      convenioNombre: "",
      convenioOsId: null,
      convenioBaja: null,
      parentescoParId: null,
      parentescoNombre: "",
      parentescoEdadDesde: null,
      parentescoEdadHasta: null,
      parentescoBajaAutomatica: false,
      parentescoMotBajaId: null,
      parentescoMotivoBaja: [],
      parentescoMotivoBajaSelected: null,
      parentescoSinCertEstudio: false,
      cobEspecialCobId: null,
      cobEspecialNombre: "",
      cobEspecialEsCertEstudio: false
    };
  },
  created() {
    this.setBajasAutomaticas();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getConfigBajasAutomaticasConvenios:
        "afiliaciones/getConfigBajasAutomaticasConvenios",
      getConfigBajasAutomaticasParentescos:
        "afiliaciones/getConfigBajasAutomaticasParentescos",
      getConfigBajasAutomaticasCoberturasEspeciales:
        "afiliaciones/getConfigBajasAutomaticasCoberturasEspeciales",
      getTiposMovMotivos: "afiliaciones/getTiposMovMotivos",
      postConfigBajasAutomaticas: "afiliaciones/postConfigBajasAutomaticas"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    resetInputsBajaAutomatica() {
      (this.parentescoEdadDesde = null),
        (this.parentescoEdadHasta = null),
        (this.parentescoMotivoBajaSelected = null);
    },
    setBajasAutomaticas() {
      // Convenios
      if (this.convenioObj != null) {
        this.convenioOsId = this.convenioObj.osId;
        this.convenioNombre = this.convenioObj.osNom;
        this.convenioBaja = this.convenioObj.osBajaAutoMay21;
      }

      // Parentescos
      if (this.parentescoObj != null) {
        this.setParentescoMotivosBajaCombo();

        this.parentescoParId = this.parentescoObj.parId;
        this.parentescoNombre = this.parentescoObj.parNom;
        // Este timeout es porque por algun motivo seteaba todo los inputs y se saltaban las rules
        // al darle ese micro segundo le da tiempo a setear correctamente las edades y verificar si vienen mal desde la base de datos
        setTimeout(() => {
          this.parentescoEdadDesde = this.parentescoObj.edadDesde;
          this.parentescoEdadHasta = this.parentescoObj.edadHasta;
        }, 1);
        this.parentescoBajaAutomatica = this.parentescoObj.esBajaAutomatica;
        this.parentescoMotBajaId = this.parentescoObj.motivoBajaId;
        this.parentescoMotivoBajaSelected = this.parentescoObj.motivoBajaId;
        this.parentescoSinCertEstudio = this.parentescoObj.sinCertificadoEstudio;
      }

      // Tipos de cobertura especial
      if (this.coberturaEspecialObj != null) {
        this.cobEspecialCobId = this.coberturaEspecialObj.tipoCobId;
        this.cobEspecialNombre = this.coberturaEspecialObj.tipoCobNom;
        this.cobEspecialEsCertEstudio = this.coberturaEspecialObj.esCertificadoEstudio;
      }
    },
    async setParentescoMotivosBajaCombo() {
      this.parentescoMotivoBaja = await this.getTiposMovMotivos();
    },
    async saveEditConvenio() {
      const data = {
        ConvenioConfig: {
          osId: this.convenioOsId,
          osBajaAutoMay21: this.convenioBaja
        },
        Pestaña: "C"
      };
      const res = await this.postConfigBajasAutomaticas(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async saveEditParentesco() {
      const data = {
        ParentescoConfig: {
          parId: this.parentescoParId,
          esBajaAutomatica: this.parentescoBajaAutomatica,
          edadDesde: this.parentescoEdadDesde,
          edadHasta: this.parentescoEdadHasta,
          motivoBajaId: this.parentescoMotivoBajaSelected,
          sinCertificadoEstudio: this.parentescoSinCertEstudio
        },
        Pestaña: "P"
      };
      const res = await this.postConfigBajasAutomaticas(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async saveEditCobEspecial() {
      const data = {
        CoberturaEspecialConfig: {
          tipoCobId: this.cobEspecialCobId,
          esCertificadoEstudio: this.cobEspecialEsCertEstudio
        },
        Pestaña: "CE"
      };
      const res = await this.postConfigBajasAutomaticas(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
