var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"col":"9","align":"start","align-self":"center"}},[_c('v-btn-toggle',{attrs:{"dense":"","color":"primary","group":""}},[(_vm.canSeeConvenios)?_c('v-btn',{attrs:{"value":"CONVENIOS","disabled":_vm.mostrarTablaConvenios},on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v("Convenios")]):_vm._e(),(_vm.canSeeParentescos)?_c('v-btn',{attrs:{"value":"PARENTESCOS","disabled":_vm.mostrarTablaParentescos},on:{"click":function($event){return _vm.changeTab(2)}}},[_vm._v("Parentescos")]):_vm._e(),(_vm.canSeeCoberturaEspecial)?_c('v-btn',{attrs:{"value":"COBERTURA ESPECIAL","disabled":_vm.mostrarTablaCoberturaEspecial},on:{"click":function($event){return _vm.changeTab(3)}}},[_vm._v("Tipos de cobertura especial")]):_vm._e()],1)],1)],1),(_vm.mostrarTablaConvenios)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bajasAutomaticasConveniosHeaders,"items":_vm.bajasAutomaticasConveniosItems,"item-key":"osId","search":_vm.searchConvenios},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","align-self":"center"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":""},model:{value:(_vm.searchConvenios),callback:function ($$v) {_vm.searchConvenios=$$v},expression:"searchConvenios"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20px"},on:{"click":function($event){return _vm.openModalEditConvenios(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar convenios")])])]}},{key:"item.osBajaAutoMay21",fn:function(ref){
var item = ref.item;
return [(item.osBajaAutoMay21)?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.checkIcon))]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.mostrarTablaParentescos)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bajasAutomaticasParentescosHeaders,"items":_vm.bajasAutomaticasParentescosItems,"item-key":"parId","search":_vm.searchParentescos},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","align-self":"center"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":""},model:{value:(_vm.searchParentescos),callback:function ($$v) {_vm.searchParentescos=$$v},expression:"searchParentescos"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20px"},on:{"click":function($event){return _vm.modalEditParentescos(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar parentescos")])])]}},{key:"item.esBajaAutomatica",fn:function(ref){
var item = ref.item;
return [(item.esBajaAutomatica)?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.checkIcon))]):_vm._e()]}},{key:"item.sinCertificadoEstudio",fn:function(ref){
var item = ref.item;
return [(item.sinCertificadoEstudio)?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.checkIcon))]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.mostrarTablaCoberturaEspecial)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bajasAutomaticasCoberturasEspecialesHeaders,"items":_vm.bajasAutomaticasCoberturasEspecialesItems,"item-key":"parId","search":_vm.searchTipoCobEspecial},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","align-self":"center"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":""},model:{value:(_vm.searchTipoCobEspecial),callback:function ($$v) {_vm.searchTipoCobEspecial=$$v},expression:"searchTipoCobEspecial"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20px"},on:{"click":function($event){return _vm.openModalEditTipoCoberturaEspecial(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar tipo de cobertura especial")])])]}},{key:"item.discapacidad",fn:function(ref){
var item = ref.item;
return [(item.discapacidad)?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.checkIcon))]):_vm._e()]}},{key:"item.esCertificadoEstudio",fn:function(ref){
var item = ref.item;
return [(item.esCertificadoEstudio)?_c('v-icon',{staticClass:"primary--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.checkIcon))]):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-8 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.modalEdits)?_c('v-dialog',{attrs:{"max-width":_vm.modalEditMaxWidth,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal($event)}},model:{value:(_vm.modalEdits),callback:function ($$v) {_vm.modalEdits=$$v},expression:"modalEdits"}},[_c('EditConfigBajasAutomaticas',{attrs:{"convenioObj":_vm.convenioObj,"parentescoObj":_vm.parentescoObj,"coberturaEspecialObj":_vm.coberturaEspecialObj},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }